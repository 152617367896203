<template>
  <div class="center">
    <span style="white-space: nowrap; margin-right: 8px">數量</span>
    <el-input-number size="small" v-model="num" :max="99" />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import storage from "@u/storage";
storage.config = { type: "session" };
export default {
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const cartList = computed(() => store.getters["carts/cartList"]);

    const num = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });

    //一開始就要取資料

    return {
      cartList,
      num,
    };
  },
};
</script>

<style lang="scss" scoped></style>
